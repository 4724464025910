import { Card, CardContent, Chip } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

const pageTitle = "Cancelled payment"

const CancelledPayment = () => (
  <Layout>
    <SEO title={pageTitle} description="The mission of WorkWithVisa.com is to find companies that provide Visa sponsorship for talented people."/>
    <Card>
        <CardContent>
        <Chip
            icon={<WarningIcon />}
            label={pageTitle}
            color="secondary"
            variant="outlined"
        />
        <div>
        <br />
        Your payment has been cancelled.
        </div>
        <br /><br /><br /><br />
        <Chip
            icon={<HelpIcon />}
            label="What to do next?"
            color="primary"
            variant="outlined"
        />
        <ul>
            <li>
                Did you cancel the payment by accident? No problem!<br /><strong>Please start it again from your personal link</strong> (sent in e-mail).
            </li>
            <li>
                Do you have any issues with the payment? <a href="mailto:info@workwithvisa.com?subject=payment">
                <Chip
                    label="Contact us!"
                    color="primary"
                    size="small"
                    clickable
                    />
                </a>
            </li>
        </ul>
        </CardContent>
    </Card>
  </Layout>
)

export default CancelledPayment
